import noProcessorsDark from "../../assets/images/no-processors-dark.png";
import noProcessorsLight from "../../assets/images/no-processors-light.png";
import { useTheme } from "@mui/material";
const NoProcessorError = () => {
  const theme = useTheme();
  return (
    <div className="error-container">
      <div className="centered-block">
        <div className="centered-image no-processors">
          <h1>No Processors Defined</h1>
          <img
            src={
              theme.palette.mode === "dark"
                ? noProcessorsDark
                : noProcessorsLight
            }
          ></img>
        </div>
        <p>
          A payment cannot be processed at this time because there are no
          processors associated with this gateway.
        </p>
      </div>
    </div>
  );
};

export default NoProcessorError;
