// global const variables want to keep the strings ambiguous to user
export const TOKEN_KEY = "jwt";
export const USER_TYPE_KEY = "ut";
export const ROLE_KEY = "r";
export const NAME_KEY = "n";

export const MERCHANT_ROLES = {
  ADMIN: "ADMIN",
  STANDARD: "STANDARD",
};

export const USER_ROLES = {
  SUPER_USER: "SU",
  SYSTEM_ADMIN: "SA",
  GATEWAY_ADMIN: "GA",
  GATEWAY_STANDARD: "GS",
};

export const NATIVE_USER_ROLE = {
  ADMIN: "admin",
  STANDARD: "standard",
};

export const USER_STATUS = {
  active: "Active",
  disabled: "Disabled",
  pending: "InvitePending",
  tos: "PendingTOS",
};

export const GATEWAY_STATUS = {
  PENDING: "pending_tos",
  ACTIVE: "active",
  DISABLED: "disabled",
  IN_MIGRATION_QUEUE: "inmigrationqueue",
  MIGRATING: "migrating",
  MIGRATION_FAILED: "migrationfailed",
};

export const GATEWAY_SETTINGS = {
  MFA_ENABLED: "mfa_enabled",
};

export const WEBHOOK_TYPES_ENUMS = {
  Transaction: "webhook_transaction_urls",
  Settlement: "webhook_settlement_urls",
  Invoice: "webhook_invoice_urls",
};
export const SUPPORT_NUMBER = "(888) 366-1325";
export const CONTACT_SUPPORT = `Please contact support: ${SUPPORT_NUMBER}`;
export const PRODUCT_DEVELOPMENT_EMAIL = "product.development@basyspro.com";

export const MILITARY_TIMES = [
  "00:00:00",
  "00:30:00",
  "01:00:00",
  "01:30:00",
  "02:00:00",
  "02:30:00",
  "03:00:00",
  "03:30:00",
  "04:00:00",
  "04:30:00",
  "05:00:00",
  "05:30:00",
  "06:00:00",
  "06:30:00",
  "07:00:00",
  "07:30:00",
  "08:00:00",
  "08:30:00",
  "09:00:00",
  "09:30:00",
  "10:00:00",
  "10:30:00",
  "11:00:00",
  "11:30:00",
  "12:00:00",
  "12:30:00",
  "13:00:00",
  "13:30:00",
  "14:00:00",
  "14:30:00",
  "15:00:00",
  "15:30:00",
  "16:00:00",
  "16:30:00",
  "17:00:00",
  "17:30:00",
  "18:00:00",
  "18:30:00",
  "19:00:00",
  "19:30:00",
  "20:00:00",
  "20:30:00",
  "21:00:00",
  "21:30:00",
  "22:00:00",
  "22:30:00",
  "23:00:00",
  "23:30:00",
  "24:00:00",
];

export const Environments = {
  Development: "Development",
  Sandbox: "Sandbox",
  Production: "Production",
};

export const GATEWAY_PROVIDER_IDS = {
  BASYSIQ: "c1907e7f-fd77-49d9-a2e7-7d3e1a6e161e",
  IQPRO: "bf7b7c9a-1e63-4a00-8373-7cdd81e517c3",
};

export const SYSTEM_PERMISSION_CODES = {
  create: "CG",
  edit: "EG",
  editGatewayStatus: "EGS",
};

export const GATEWAY_USER_ROLE_CODES = {
  standard: "5c1ed3ae-8584-41b0-a56a-3e023559eee2",
  admin: "20e6c0ff-30c8-44dd-ac16-630e8e0957f9",
};

export const USER_STATUS_CODES = {
  InvitePending: 0,
  PendingTOS: 1,
  Active: 2,
  Disabled: 3,
};

export const GATEWAY_USER_PERMISSION_CODES = {
  standard: [
    "PROCESS_CAPTURE",
    "PROCESS_SALE",
    "VAULT_UPDATE",
    "VAULT_CREATE",
    "PROCESS_VOID",
    "PROCESS_REFUND",
    "PROCESS_VERIFICATION",
    "PROCESS_AUTHORIZATION",
    "VAULT_DELETE",
    "CREATE_INVOICE",
    "EDIT_INVOICE",
    "READ_INVOICE",
    "CREATE_PRODUCT",
    "EDIT_PRODUCT",
    "DELETE_PRODUCT",
    "VIEW_CUST_PAYABLE_TOKEN",
    "MANAGE_CUST_PAYABLE_TOKEN",
  ],
  admin: [
    "PROCESS_CAPTURE",
    "PROCESS_SALE",
    "VAULT_UPDATE",
    "VAULT_CREATE",
    "PROCESS_VOID",
    "PROCESS_REFUND",
    "PROCESS_CREDIT",
    "PROCESS_VERIFICATION",
    "VIEW_OTHERS_TRANSACTIONS",
    "PROCESS_AUTHORIZATION",
    "VAULT_DELETE",
    "VIEW_SETTLEMENT_BATCHES",
    "CREATE_INVOICE",
    "EDIT_INVOICE",
    "READ_INVOICE",
    "DELETE_INVOICE",
    "VIEW_ALL_INVOICES",
    "CREATE_PRODUCT",
    "EDIT_PRODUCT",
    "DELETE_PRODUCT",
    "VIEW_CUST_PAYABLE_TOKEN",
    "MANAGE_CUST_PAYABLE_TOKEN",
    "CREATE_SUBSCRIPTION",
    "READ_SUBSCRIPTION",
    "EDIT_SUBSCRIPTION",
    "DELETE_SUBSCRIPTION",
    "VIEW_ALL_SUBSCRIPTIONS",
    "CREATE_SIMPLEPAY",
    "READ_SIMPLEPAY",
    "EDIT_SIMPLEPAY",
    "DELETE_SIMPLEPAY",
  ],
};

export const ROLES_AND_PERMISSIONS_DEPENDENCIES = {
  GATEWAY_USER: "/merchant/manage/users",
};

export const INVOICE_STATUSES = {
  DRAFT: 1,
  SCHEDULED: 2,
  SENT: 3,
  PAID: 4,
  PARTIALLY_PAID: 5,
  PAYMENT_PENDING: 6,
  OVERDUE: 7,
  CANCELLED: 8,
  DELETED: 9,
};

export const SEC_CODES = {
  PPD: 0,
  CCD: 1,
  WEB: 2,
  TEL: 3,
};

export const DEFAULT_REQUIRED_FIELDS = {
  firstName: false,
  lastName: false,
  company: false,
  address1: false,
  addressLine1: false,
  address2: false,
  addressLine2: false,
  country: false,
  state: false,
  city: false,
  postalCode: false,
  email: false,
  phone: false,
  fax: false,
};

export const iqProVer = {
  v1: "IQPRO",
  v2: "IQPROV2",
};

export const SUBSCRIPTION_STATUSES = {
  DRAFT: "Draft",
  SCHEDULED: "Scheduled",
  TRIALING: "Trialing",
  ACTIVE: "Active",
  OVERDUE: "Overdue",
  PAUSED: "Paused",
  CANCELLED: "Cancelled",
  DELETED: "Deleted",
  COMPLETE: "Complete",
};

export const SUBSCRIPTION_STATUS_IDS = {
  DRAFT: 1,
  SCHEDULED: 2,
  TRIALING: 3,
  ACTIVE: 4,
  OVERDUE: 5,
  PAUSED: 6,
  CANCELLED: 7,
  DELETED: 8,
  COMPLETE: 9,
};

export const BILLING_PERIODS = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  BIWEEKLY: "Biweekly",
  MONTHLY: "Monthly",
  QUARTERLY: "Quarterly",
  YEARLY: "Yearly",
};

export const TRANSACTION_RESPONSES = {
  UNKNOWN: "unknown",
  DECLINED: "declined",
  AUTHORIZED: "authorized",
  PENDING_SETTLEMENT: "pendingsettlement",
  SETTLED: "settled",
  VOIDED: "voided",
  REFUNDED: "refunded",
  RETURNED: "returned",
  LATE_RETURN: "latereturn",
  PENDING: "pending",
  PARTIALLY_REFUNDED: "partiallyrefunded",
  APPROVED: "approved",
};
