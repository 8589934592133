import axios from "axios";
import { useEffect, useState } from "react";
import { sassEndpoints } from "../../../../../constants/endpoints";
import { USER_STATUS, GATEWAY_SETTINGS } from "../../../../../constants/global";
import { GatewaySettingsContext } from "../../gatewaySettingsContext";
import { useContext } from "react";
import PropTypes from "prop-types";
import MUISwitch from "@mui/material/Switch";
import { Button } from "@mui/material";
import StyledTabs from "../../../../Tabs/StyledTabs";
import "../../../../../assets/v2/main.scss";
import { Grid } from "@mui/material";
import { stringFormat } from "../../../../../utils/stringHelpers";
import GatewayUserSigninLogs from "./GatewayUserSigninLogs";
import GatewayUserPermissions from "./GatewayUserPermissions";
import GatewayUserInfo from "./GatewayUserInfo";
import emptyState from "../../../../../assets/images/empty-state.png";
import { GatewayUserDetailContext } from "../../../../../contexts/GatewayUserDetailContext";

function GatewayUserDetailContainer({
  gatewayInfo,
  gatewayId,
  warningMessage,
  resendEmail,
  showSnackbar,
  setShowBackdrop,
  setAlertDialogOpen,
  setAlertDialogProps,
}) {
  const [userData, setUserData] = useState(null);
  const [callSignInLogs, setCallSignInLogs] = useState(false);
  const [bypassChecked, setBypassChecked] = useState(false);
  const [optInChecked, setOptInChecked] = useState(false);
  const [optInDisabled, setOptInDisabled] = useState(true);
  const { gatewayProps, setGatewayProps } = useContext(GatewaySettingsContext);
  const [gatewayRole, setGatewayRole] = useState(null);
  const [gatewayUserStatus, setGatewayUserStatus] = useState(null);
  const [userStatus, setUserStatus] = useState(null);

  const editUser = async (payload, changingUserStatus) => {
    let url = sassEndpoints.users.user + `/${userData?.user.userId}`;
    return axios
      .put(url, payload)
      .then(() => {
        if (changingUserStatus) {
          setUserStatus(payload.status);
        }
        showSnackbar("User updated");
      })
      .catch(() => {
        warningMessage("An error has occurred while processing your request!");
      })
      .finally(() => {
        getUserData(gatewayProps.selectedUser, false);
      });
  };

  const editGatewayUser = (payload, gatewayUserId, changeField) => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [gatewayId]) +
      "/" +
      gatewayUserId +
      "?isSystemUserId=false";
    setShowBackdrop(true);
    axios
      .put(url, payload)
      .then(function () {
        if (changeField && changeField === "role") {
          setGatewayRole(payload.roleId);
        } else if (changeField && changeField === "status") {
          setGatewayUserStatus(payload.gatewayUserStatus);
        }
        showSnackbar("User updated");
      })
      .catch(function () {
        warningMessage("An error has occurred while processing your request!");
      })
      .finally(function () {
        setShowBackdrop(false);
        getUserData(gatewayProps.selectedUser, false);
      });
  };

  const allowCustomerPayable =
    gatewayInfo?.gatewaySettings
      ?.find((obj) => obj.code.toLowerCase() === "customer_payable")
      ?.value.toLowerCase() === "true";

  useEffect(() => {
    getUserData(gatewayProps.selectedUser, false);
  }, []);

  const getUserData = (id, refresh) => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [gatewayId]) +
      "/" +
      id +
      "?isSystemUserId=false";
    setGatewayProps({
      ...gatewayProps,
      isLoading: true,
    });
    axios
      .get(url)
      .then(function (response) {
        if (response.status === 204) {
          warningMessage("Failed to get user details");
          setUserData([]);
        }
        setGatewayProps({
          ...gatewayProps,
          isDisabled: refresh || gatewayProps.isDisabled,
        });
        let respObj = response?.data?.data;
        setBypassChecked(respObj?.user?.bypassMfa);
        setOptInChecked(respObj?.user?.mfaEnabled);
        setOptInDisabled(
          respObj?.user?.mfaEnabled &&
            gatewayInfo?.gatewaySettings
              ?.find(
                (obj) =>
                  obj.code.toLowerCase() ===
                  GATEWAY_SETTINGS.MFA_ENABLED.toLowerCase(),
              )
              ?.value.toLowerCase() === "true",
        );
        setUserData(respObj);
        setUserStatus(respObj?.user?.status);
        setGatewayRole(respObj?.role?.roleId);
        setGatewayUserStatus(respObj?.gatewayUserStatus);
      })
      .catch(function () {
        warningMessage("Failed to get user details");
        setUserData([]);
      })
      .finally(() => {
        setGatewayProps({
          ...gatewayProps,
          isLoading: false,
        });
      });
  };

  const displayedPermissions = gatewayProps?.gatewayPermissions?.filter(
    (perm) => {
      return (
        perm?.code?.toLowerCase() !== "vault_read" &&
        perm?.code?.toLowerCase() !== "manage_users" &&
        !(
          (perm?.code?.toLowerCase() === "manage_cust_payable_token" ||
            perm?.code?.toLowerCase() === "view_cust_payable_token") &&
          !allowCustomerPayable
        )
      );
    },
  );

  const handlePayloadPermissions = (permissionName, checked) => {
    let existingPermissionIds = userData?.gatewayUserPermissions?.map(
      (p) => p.permissionId,
    );
    if (!permissionName && !checked) {
      return existingPermissionIds;
    } else if (checked) {
      let addPermissionId = displayedPermissions.find(
        (p) => p.code === permissionName,
      ).permissionId;
      existingPermissionIds.push(addPermissionId);
      return existingPermissionIds;
    } else {
      let permissionRemoving = userData?.gatewayUserPermissions.find(
        (p) => p.code === permissionName,
      ).permissionId;
      let finalIds = existingPermissionIds.filter(
        (id) => id !== permissionRemoving,
      );
      return finalIds;
    }
  };

  const changeMFA = async (bypassMfa, mfaEnabled) => {
    const payload = {
      firstName: userData?.user?.firstName,
      lastName: userData?.user?.lastName,
      status: userData?.user?.status,
      phone: userData?.phone,
      bypassMfa: bypassMfa,
      mfaEnabled: mfaEnabled,
    };
    editUser(payload);
  };

  const deleteMfa = async () => {
    let url = stringFormat(sassEndpoints.users.resetMfa, [
      userData?.user?.userId,
    ]);
    axios
      .delete(url)
      .then(() => {
        showSnackbar("Reset MFA settings!");
      })
      .catch(() => {
        warningMessage("Failed to reset MFA settings");
      })
      .finally(() => {
        getUserData(gatewayProps.selectedUser, false);
      });
  };

  const triggerAlert = (alertMessages, confirmButton, onClose) => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: alertMessages,
      actionButtons: [confirmButton],
      closeButtonText: "Cancel",
      closeButtonColor: "neutrals",
      onCloseButtonClick: onClose
        ? onClose
        : () => {
            setAlertDialogProps({});
            setAlertDialogOpen(false);
          },
    });
    setAlertDialogOpen(true);
  };

  const deleteUser = (gatewayUserId) => {
    let url =
      stringFormat(sassEndpoints.gatewayUsers.user, [gatewayInfo.gatewayId]) +
      "/" +
      gatewayUserId +
      "?isSystemUserId=false";
    setShowBackdrop(true);

    axios
      .delete(url)
      .then(function () {
        showSnackbar("Deleted User");
        setGatewayProps({
          ...gatewayProps,
          usersState: "DEFAULT",
        });
      })
      .catch(function () {
        warningMessage("Failed to delete user");
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  return (
    <div className="v2">
      {userData && Object.keys(userData).length > 0 ? (
        <GatewayUserDetailContext.Provider
          value={{
            userData,
            gatewayRole,
            gatewayUserStatus,
            userStatus,
            triggerAlert,
            setAlertDialogOpen,
            setAlertDialogProps,
            editGatewayUser,
            editUser,
            handlePayloadPermissions,
          }}
        >
          <Grid
            container
            flexDirection={"column"}
            className="gateway-user-container"
          >
            <Grid item>
              <Grid
                container
                flexDirection={"row"}
                className="card-container gateway-user-info"
                justifyContent="space-between"
                style={{ marginBottom: "16px" }}
              >
                <GatewayUserInfo
                  resendEmail={resendEmail}
                  gatewayProps={gatewayProps}
                  deleteUser={deleteUser}
                  setGatewayProps={setGatewayProps}
                />
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    flexDirection={"column"}
                    padding="16px"
                    className="card-obj mfa"
                    justifyContent={"space-between"}
                  >
                    <Grid container flexDirection={"column"}>
                      <Grid item className="mfa-label">
                        Bypass MFA
                        <MUISwitch
                          name={"bypass_mfa"}
                          checked={bypassChecked}
                          disabled={
                            userData?.user?.status.toLowerCase() ===
                            USER_STATUS.pending.toLowerCase()
                          }
                          onChange={(e) => {
                            let changeOptIn = false;
                            if (e.target.checked && optInChecked) {
                              changeOptIn = true;
                            }
                            changeMFA(
                              e.target.checked,
                              changeOptIn ? false : optInChecked,
                            );
                          }}
                        />
                      </Grid>{" "}
                      <Grid item className="caption">
                        Bypassing at this level will deactivate MFA across all
                        the gateways associated with this user. Exercise caution
                        before proceeding.{" "}
                      </Grid>{" "}
                      <Grid item className="mfa-label">
                        Opt In MFA
                        <MUISwitch
                          name={"optin_mfa"}
                          checked={optInChecked}
                          disabled={
                            userData?.user?.status.toLowerCase() ===
                              USER_STATUS.pending.toLowerCase() || optInDisabled
                          }
                          onChange={(e) => {
                            let changeBypass = false;
                            if (bypassChecked && e.target.checked) {
                              changeBypass = true;
                            }
                            changeMFA(
                              changeBypass ? false : bypassChecked,
                              e.target.checked,
                            );
                          }}
                        />
                      </Grid>{" "}
                      <Grid item className="caption">
                        This will force the user to require MFA regardless of
                        their gateway setting.
                      </Grid>
                    </Grid>

                    {userData?.user?.totpEnabled && (
                      <Grid item style={{ alignSelf: "center" }}>
                        <Button
                          className="btn--secondary__danger btn sm"
                          onClick={() => {
                            triggerAlert(
                              ["You are resetting your MFA settings"],
                              {
                                text: "Yes, reset MFA!",
                                color: "secondary",
                                onclick: () => {
                                  deleteMfa();
                                  setAlertDialogProps({});
                                  setAlertDialogOpen(false);
                                },
                              },
                            );
                            setAlertDialogOpen(true);
                          }}
                          disabled={
                            userData?.user?.status.toLowerCase() ===
                            USER_STATUS.pending.toLowerCase()
                          }
                        >
                          Reset MFA
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="card-container">
              <div className="card-obj">
                <StyledTabs
                  label="gateway details"
                  defaultTabIndex={0}
                  onChange={(e) => {
                    if (e === 1) {
                      setCallSignInLogs(true);
                    }
                  }}
                  tabs={[
                    {
                      props: { label: "Permissions" },
                      panel: (
                        <GatewayUserPermissions
                          displayedPermissions={displayedPermissions}
                        />
                      ),
                    },
                    {
                      props: { label: "Sign In Logs" },
                      panel: (
                        <GatewayUserSigninLogs
                          warningMessage={warningMessage}
                          callSignInLogs={callSignInLogs}
                          setCallSignInLogs={setCallSignInLogs}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        </GatewayUserDetailContext.Provider>
      ) : (
        userData?.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <img src={emptyState}></img>
            <p>Unable to fetch user data</p>
          </div>
        )
      )}
    </div>
  );
}

GatewayUserDetailContainer.propTypes = {
  gatewayInfo: PropTypes.object,
  gatewayId: PropTypes.string,
  warningMessage: PropTypes.func,
  setShowBackdrop: PropTypes.func,
  resendEmail: PropTypes.func,
  showSnackbar: PropTypes.func,
  setAlertDialogOpen: PropTypes.func,
  setAlertDialogProps: PropTypes.func,
};

export default GatewayUserDetailContainer;
